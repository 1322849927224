.popup-milestone {
  margin: auto auto;
  margin-top: 3%;
  padding: 10px 5px 10px 20px;
  background: #fff;
  border-radius: 24px;
  width: 30%;
  max-width: 800px;
  height:58%;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  max-height: 535px;
  min-height: 500px;
  outline: 0;
}
@media (max-width: 1200px) {
  .popup-milestone {
    margin-top: 2%;
    padding: 10px 10px 10px 20px;
    width: 98%;
    height: max-content;

  }}
  @media (max-width: 500px) {
    .popup-milestone {
      padding: 10px 5px 10px 15px;
    }}
