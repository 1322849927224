.left-side-what-gyld-dev {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 5% 1% 5% 5%;
}
@media (max-width: 800px) {
  .left-side-what-gyld-dev {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
 
  }
}

.what-gyld-dev {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #fffcf0;
}
@media (max-width: 1200px) {
  .what-gyld-dev {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 10%;
  }
}


.what-gyld-image-dev {
  width: 40%;
  height: 100%;
  min-width: 400px;
}
@media (max-width: 800px) {
  .what-gyld-image-dev {
    width: 100%;
    min-width: 280px;
  }
}

.cta-image-dev {
  position: relative;
  left: 0;
  top: -70px;
  margin-right: 80px;
}

.dev-image {
  width: 13% !important;
  min-width: 200px;
  pointer-events: none;
  margin-top: 50px;
}
@media (max-width: 400px) {
  .dev-image {
    width: 0%;
    min-width: 155px;
    margin-left: 0px;
  }
}

.dev-image1 {
  width: 33% !important;
  min-width: 300px;
  pointer-events: none;

}
@media (max-width: 400px) {
  .dev-image1 {
    min-width: 170px;
  }}

.dev-carousel-buttons {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-radius: 8px;
}
@media (max-width: 800px) {
  .dev-carousel-buttons {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
    align-content: center;
  }}
  @media (max-width: 450px) {
    .dev-carousel-buttons {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width:100%;
      align-items: center;
      height: 180px;
    }}


    .dev-image2 {
      width: 60% !important;
      min-width: 300px;
      pointer-events: none;
      margin-top: 140px;
    
    }
    @media (max-width: 400px) {
      .dev-image2 {
        min-width: 170px;
        margin-top: 0px;
      }}
    

.home-title-dev {
  /* line-height: 72px; */
  text-align: left;
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 51px;
  color: #000000;
  padding-bottom: 20px;
}
@media (max-width: 800px) {
  .home-title-dev {
align-self: center;
  }
}


.dev-row-dots{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0px 0px 0px;
}
@media (max-width: 1200px) {
  .dev-row-dots{
   opacity: 0;
  }}

.dev-dots{
  height: 10px;
  width: 10px;
  background-color: #656565;
  border-radius: 20px;
}


.dev-row-space-between-center{
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
}
@media (max-width: 1200px) {
  .dev-row-space-between-center{
    display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  }}


  .dev-leftSideHeader{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    width: 50%;
    padding-top: 110px;
  }
  @media (max-width: 1200px) {
    .dev-leftSideHeader{
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: start;
      width: 100%;
      padding-top: 20px;
    }}


    .row-reverse-space-between-center {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    @media (max-width: 1200px) {
      .row-reverse-space-between-center {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }}

    .what-gyld-dev {
      display: flex;
      flex-direction: row;
      margin-bottom: 100px;
align-items: flex-end;
      flex-wrap: wrap;
      width: 100%;
      /* height:500px; */
      background-color: transparent;
    }
    @media (max-width: 1200px) {
      .what-gyld-dev {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
        /* padding-top: 100px; */
        /* height: 25%; */
        padding-bottom: 50px;
      }
    }
    

  .what-image-dev {
    width: 40%;
    /* height: 100%; */
  }
  @media (max-width: 1200px) {
    .what-image-dev {
      width: 100%;
      height:400px;
    }
  }

  .right-side-what-gyld-dev {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4% 4% 4% 4%;
    background-color: #faf6ff;
    width: 60%;
    max-height: 100%;
  gap: 10px;
  }
  
  @media (max-width: 1200px) {
    .right-side-what-gyld-dev {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px 20px 40px 20px;
      width:100%;
      align-items: center;
    }
  }
  @media (max-width: 450px) {
    .right-side-what-gyld-dev {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px 10px 40px 5px;
      width:100%;
      align-items: center;
    }
  }
  