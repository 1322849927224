.popup-add-user {
    margin: 70px auto;
    padding: 10px 15px;
    background: #fff;
    border-radius: 24px;
    width: 45%;
    max-height: 90%;
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 850px;
    outline: 0;
}

@media (max-width: 1200px) {
    .popup-add-user {
        padding: 3% 4%;
        width: 70%;
    }
}
@media (max-width: 500px) {
    .popup-add-user {
        width: 90%;
    }
}


.add-user-box-width {
    height: 80px;
    width: 40%;
}

@media (max-width: 800px) {
    .add-user-box-width {
        width: 100%;
    }
}

.add-user-row-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
@media (max-width: 800px) {
    .add-user-row-sb {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}