.mobile-popup-add-request{
    
    margin: auto auto;
    margin-top: 1%;
    height: 97%;
    width: 97%;
    border-radius: 24px;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: hidden;
    outline: 0;
    background-color: rgb(255, 255, 255);
    padding: 10px 7px 10px 20px;
}

.mobile-add-request-scroll{
overflow-y: scroll;
scrollbar-color: #c8c5c5 transparent;
scrollbar-width: thin;
overflow-x: hidden;
padding-right: 13px;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
height: 100%;
}

.add-attachment{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
padding-left: 20px;
}