.profile-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border: 8px solid #f0f5ff;
  border-radius: 24px;
  padding: 2% 3%;
}

@media (max-width: 1200px) {
  .profile-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5% 10%;
  }
}

.profile-page-pic {
  width: 140px;
  height: 140px;
  min-width: 140px;
  min-height: 140px;
  border-radius: 70px;
  position: relative;
}


.no-pic {
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  text-align: center;
}

.profile-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}


.text-change-picture {
  width: max-content;
}

.text-name {
  margin-top: 10px;
}

.profile-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1200px) {
  .profile-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}


.profile-add-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  padding: 2% 3%;
  margin-top: 50px;
  height: max-content;
}

@media (max-width: 1200px) {
  .profile-add-skills {
    padding: 5% 10%;
  }
}


.column-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.row-skills {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

@media (max-width: 1200px) {
  .row-skills {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

  }
}


.add {
  /* margin-top: 5px; */
  margin-right: 3px;
}

.add-skills-div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 2%;
}

.profile-save-button {
  background-color: #626da1 !important;
  color: white !important;
  border-radius: 8px !important;
  align-self: flex-end;
  margin-top: 20px !important;
  text-transform: none !important;
  font-family: Proxima Nova !important;
  font-weight: 700 !important;
}

@media (max-width: 1200px) {
  .profile-save-button {
    margin-bottom: 40px !important;
  }
}


.profile-skills-width {
  width: 40%;
}

@media (max-width: 1200px) {
  .profile-skills-width {
    width: 100%;
  }
}


.profile-availability-width {
  width: 40%;
}

@media (max-width: 1200px) {
  .profile-availability-width {
    width: 100%;
  }
}



.availability-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .availability-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 17vh;
  }
}



.css-1wc848c-MuiFormHelperText-root.Mui-error {
  margin-left: 0px;
  margin-right: 0px;
}


.profile-box-height {
  height: 80px;
  width: 30%;
}

@media (max-width: 1200px) {
  .profile-box-height {
    height: 80px;
    width: 100%;
  }
}


.react-time-picker__wrapper{
display: flex;
flex-grow: 1;
flex-shrink: 0;
border: 1px solid #c4c4c4 !important;
padding: 10px;
height: 43px;
border-radius: 8px;
}