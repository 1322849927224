/* .login-padding{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 64px;
    padding-top: 30px;
} */

.login-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.login-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
  height:450px;
    max-width:400px;
    padding: 40px 30px;
}
@media (max-width: 1200px) {
    .login-card{
        padding: 30px 20px;
    }}


    .login-card-change{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
      height:500px;
        max-width:400px;
        padding: 40px 30px;
    }
    @media (max-width: 1200px) {
        .login-card-change{
            padding: 30px 20px;
        }}
.login-height{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height:100px;
    width: 100%;
}

.login-button{
    background-color: #0645ad ;
border-radius: 8px;
color:#fff;
height: 38px;
width:80%;
font-family: Proxima Nova;
font-weight: 700;
align-self: center;
}
.login-button-change{
    background-color: #0645ad ;
    border-radius: 8px;
    color:#fff;
    height: 38px;
    width:80%;
    font-family: Proxima Nova;
    font-weight: 700;
    align-self: center;
    }

.login-width{

    min-width: 100px;
    width:100%
}