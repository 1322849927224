.card-project {
  /* background: #ebebeb; */
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 40px;
  height:auto
}


.profile-close{
   position: absolute;
  top: 20px;
  right: 30px;
}

.skills-project {
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}


