
.opp-box-phases-half{
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: #fff;
border-radius: 24px;
padding: 20px 20px 20px 25px;
min-height: 250px;
height: max-content;
margin-top: 20px;
}
@media (max-width: 1200px) {
    .opp-box-phases-half{
        padding: 15px;}}
