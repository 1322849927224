.client-user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  margin: auto auto;
  margin-top: 3%;
  background: #fff;
  border-radius: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 40%;
  max-width: 800px;
  min-width: 550px;
  height: 400px;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 800px) {
  .client-user {
    width: 98%;
    min-width: unset;
  }
}

@media (max-width: 400px) {
  .client-user {
    height: max-content;
  }
}


.profile-card-public {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  border: 8px solid #f0f5ff;
  border-radius: 24px;
  padding: 30px 50px;
  margin-top: 20px;
  height: 100%;
}

@media (max-width: 400px) {
  .profile-card-public {
    display: flex;
    flex-direction: column;
  }
}

.client-user-prof-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-user-column-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
}