.popup-timesheet {
    margin: auto auto;
    margin-top: 3%;
    padding: 10px 5px 10px 20px;
    background: #fff;
    border-radius: 24px;
    width: 30%;
    min-width: 470px;
    max-width: 800px;
    height: 38%;
    min-height: 300px;
    max-height: 500px;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: hidden;
    outline: 0;
  }
  @media (max-width: 1200px) {
    .popup-timesheet {
      margin-top: 3%;
      width: 98%;
      padding: 15px 5px 15px 15px;
      min-width: unset;
      
    }}

  .timesheet-scroll{
overflow-y: scroll;
scrollbar-color: #c8c5c5 transparent;
scrollbar-width: thin;
overflow-x: hidden;
padding-right: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
height: 100%;
}


.timesheet-column{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.timesheet-width{
  width:35%;

}
@media (max-width: 550px) {
  .timesheet-width{
    width:80%;
  
  }}

.timesheet-width-small{
  width:16%;
}
@media (max-width: 550px) {
  .timesheet-width-small{
    width:80%;
  }}

.timesheet-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 550px) {
  .timesheet-row{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }}