.configurator-out {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 25px 0px 25px;
    border-radius: 24px;
    width: 100%;
    margin-bottom: 50px;
    height: 790px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1200px) {
    .configurator-out {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: unset;
        margin-top: 50px;
    }
}

.calculator-image{
    opacity:0.7;
    margin-top:12px;
    width: 370px;
}
@media (max-width: 1200px) {
    .calculator-image{
       
        margin-top:12px;
        width: 300px;
    }}

.configurator-grey-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 660px;
    border-radius: 24px;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #eeeeee;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12); */
}

@media (max-width: 1200px) {
    .configurator-grey-bottom {
        padding: 10px
    }
}

.mob-configurator-grey-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.configurator-bottom-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 40%;
    height: max-content;
    /* border-right: 1px solid #bdbdbd; */
    padding: 0px 20px 15px 15px;
    max-height: 600px;
}

@media (max-width: 1200px) {
    .configurator-bottom-left {
        padding: 0px 10px 5px 0px
    }
}

.mob-configurator-bottom-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 10px;
    max-height: 500px;
}

.configurator-bottom-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-left: 1px solid #bdbdbd;
}

.mob-configurator-bottom-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-top: 1px solid #bdbdbd;
    padding-top: 15px;
    margin-top: 15px;
}



/* .config-box-width-small {
    width: 40%;
    height: max-content;
    /* border-right: 1px solid #bdbdbd; */
/* padding: 0% 3% 1% 1%;
    max-height: 500px;
  } */

.configurator-bottom-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-left: 1px solid #bdbdbd;
}

.configurator-column-list-scroll {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #c8c5c5 transparent;
    scrollbar-width: thin;
    padding-top: 20px;
}


.configurator-list-row-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    background-color: #fff;
    padding: 1.5%;
    border-radius: 20px;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .configurator-list-row-sb {
        width: 89%;
        padding: 10px
    }
}


.mob-configurator-list-row-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
    background-color: #fff;
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.config-box-width-big {
    width: 100%;
}

.mob-config-box-width-big {
    width: 48%;
}
@media (max-width: 450px) {
    .mob-config-box-width-big {
        width: 100%;
    }}


.configurator-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 2%;
}

.config-info-out {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0% 0% 0% 6%;

}

.config-right-overflow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: auto;
    scrollbar-color: whitesmoke transparent;
}

.config-info-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 2%;

}


.config-column-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.row-start-config-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 95%;
    border-bottom: 1px solid #0094ff;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.pricing-total-cost {
    color: #ff6210;
    font-family: "Proxima Nova";
    font-weight: 700;
    font-size: 18px;
    padding-top: 10px;
    align-self: center;
}

.mob-config-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 450px) {
    .mob-config-row-space-between {
        display: flex;
        flex-direction: column;
        width: 100%;
      }}

      .contact-row{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-right: 160px;
      }
    

        .mob-contact-row{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-right: unset;
            padding-bottom: 15px;
        padding-top: 15px;}