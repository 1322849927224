.navigation-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding: 10px;
  height: 100vh;
  width: 320px;
  min-width: 200px;
  background-color: #f0f5ff;
  margin-top: -80px;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
@media (max-width: 1200px) {
  .navigation-column {
    width: 290px;
  }}

.pn-logo {
  width: 108px;
  margin-bottom: 40px;
}

.pn-logo-text-small {
  font-size: 10px;
  font-family: "Proxima Nova";
}
.pn-logo-text-big {
  font-size: 40px;
  font-family: "Proxima Nova";
}

.mb {
  margin-bottom: 62px;
}

.selected-view {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Proxima Nova";
  font-weight: 700;
  color: #ff6210;
  /* margin-right: 10px; */
}

.nav-button {
  font-family: Proxima Nova;
  padding: 10px;
  width: 109%;
  margin-left: -10px;
}

.nav-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  text-decoration: none;
  color: #626da1;
  font-size: 16px;
}

.nav-icon {
  margin-left: 10px;
  margin-right: 10px;
}
