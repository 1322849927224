.popup-edit-request {
    margin: 50px auto;
    padding: 20px 30px;
    background: #fff;
    border-radius: 24px;
    width: 40%;
    /* max-height: 80%; */
    /* position: relative; */
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 850px;
    height: 820px;
  }

  .edit-request-width{
    width:45%;
  }
