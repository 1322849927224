.padding-about {
  padding: 0% 15% 0% 15%;
}
@media (max-width: 1200px) {
  .padding-about {
    padding: 0% 5% 0% 5%;
  }
}

.about-grey-back {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #f0f0f0cc;
}

.about-grey-back-founders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  width: 100%;
  padding: 20px;
  background-color: #f0f0f0cc;
}
@media (max-width: 1200px) {
  .about-grey-back-founders {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px;
  }
}

@media (max-width: 600px) {
  .about-grey-back-founders {
    padding: 20px;
  }
}

.about-title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Lora";
}

.about-img {
  width: 50% !important;
  min-width: 300px;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .about-img {
    margin-right: 0px;
  }
}

.founder-image {
  margin-right: 20px;
  max-width: 400px;
}
@media (max-width: 1200px) {
  .founder-image {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.text-Proxima Nova-lh {
  font-family: "Proxima Nova";
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
}

.about-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 600px) {
  .about-row-sb {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}
