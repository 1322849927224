.popup-phase {
  margin: auto auto;
  margin-top: 2%;
  padding: 10px 5px 10px 20px;
  background: #fff;
  border-radius: 24px;
  width: 30%;
  min-width: 500px;
  max-width: 800px;
  height: 89%;
  max-height: 850px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  outline: 0;
}
@media (max-width: 1200px) {
  .popup-phase {
     margin-top: 1%;
    width: 98%;
    padding: 10px 10px 10px 20px;
    min-width: unset;
  }}
  @media (max-width: 500px) {
    .popup-phase {
      padding: 10px 5px 10px 15px;
    }}
  

.phase-scroll{
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .phase-scroll{
    overflow-y: auto;
    overflow-x: hidden;

  width:100%;
  }}

.close {
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.close:hover {
  cursor: pointer;
  color: #000;
}

.add-phase-full-width{
  min-width:100%;
}
@media (max-width: 1200px) {
  .add-phase-full-min-width{
    min-width: 100% !important;
  }}

  .add-phase-half-width{
    width:48%;
  }
  @media (max-width: 1200px) {
    .add-phase-half-width{
      min-width: 45%;
    }}

.add-phase-row-flex-end{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
@media (max-width: 1200px) {
  .add-phase-row-flex-end{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}}
