.popup-invoice {
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 20px 20px 20px;
    background: #fff;
    border-radius: 24px;
    width: 30%;
    min-width: 450px;
    margin-top: 5%;
    transition: all 0.5s ease-in-out;
    overflow-x: hidden;
    outline: 0;
    max-width: 800px;
    min-height: 250px;
  }
  @media (max-width: 1200px) {
    .popup-invoice {
      margin-top: 2%;
  
      width: 98%;
      min-width: unset;
    }}

    .add-invoice-scroll{
      overflow-y: auto;
      overflow-x: hidden;
  width:100%  }
  
  @media (max-width: 1200px) {
    .add-invoice-scroll{
      padding-right:0px;
    }}

    .add-invoice-row-flex-end{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      /* padding-top: 8%; */
    }
    @media (max-width: 1200px) {
      .add-invoice-row-flex-end{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }}
    @media (max-width: 335px) {
      .add-invoice-row-flex-end{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        height: 105px;
    }}

.add-invoice-width{
  width: 100%;
}