.popup-invite-candi {
  margin: auto auto;
  margin-top: 2%;
  background: #fff;
  border-radius: 24px;
  width: 100%;
  /* max-height: 80%; */
  /* position: relative; */
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 800px;
  height: max-content;
  outline: 0;
  overflow-x: hidden;
  padding: 0px 2px 0px 8px;
}

.invite-candi-scroll {
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 600px;
}

@media (max-width: 1200px) {
  .invite-candi-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: unset;
    width: 100%;
  }
}

.invite-candi-row-with-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 15px 10px;
  margin-left: 10px;
}


.padding-invite-candi-top {
  padding: 10px 20px 20px 20px;
}

.invite-candi-to-pub-prof {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  padding: 10px 20px 20px 20px;
  margin: auto auto;
margin-top: 2%;
background: #fff;
border-radius: 24px;

height: max-content;
transition: all 0.5s ease-in-out;
outline: 0;
}