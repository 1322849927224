.navigationBar {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  /* box-shadow: 5px 7px 4px -6px #606060; */
  padding-right: 27px;
}

.notification-alert {
  background-color: red;
  /* padding: 0px 6px; */
  border-radius: 50px;
  color: white;
  position: absolute;
  top: 44px;
  margin-left: 20px;
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 20px;
width: 20px;
font-weight: bold;
font-size: 15px;
padding-bottom: 1px;
}
.notification-icon {
  height: 30px;
}
.notifications-open {
  position: absolute;
  top: 80px;
  width: 20%;
  box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px;
}
.menu-back {
  background-color: #f5f5f5;
}
.header-icon-color {
  color: #626da1;
  cursor: pointer;
  margin-right: 10px;
  height: 30px !important;
  width:30px !important; 
}
@media (max-width: 800px) {
  .header-icon-color {
  height: 20px;
  width:20px
  }}

.MuiList-root {
  width: 100% !important;
}
.MuiMenuItem.root {
  width: 100% !important;
  text-align: center;
}
.MuiPopover-paper {
  border-radius: 24px !important;
  /* width: 300px; */
}
.notifications-open {
  width: 400px;
}
.notification-body{
  max-height:400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.notifications-open .MuiPopover-paper {
  border-radius: 24px !important;
  width: 400px !important;
}
/* 
.MuiPaper-root {
  scrollbar-width: none;
} */
.MuiList-root:focus-visible {
  outline: none !important;
}
/* .bm-burger-bars{
position: relative;
height: 10% !important;
right: 1% !important;
top: 10% !important;
left:unset !important;
opacity: 0.6;
width: 10% !important;
background: #373a47;
} */
/* .bm-burger-bars{
  position: absolute;
  height: 10%;
  left: 0px;
  right: 1%;
  top:15%;
  opacity: 0.6;
  width: 10%;
  background: #373a47;
  }
  .bm-burger-bars{
    position: absolute;
    height: 10%;
    left: 0px;
    right: 1%;
    opacity: 0.6;
    width: 10%;
    background: #373a47;
    } */

.logo {
  font-size: 60px;
  font-weight: 800;
  margin-left: 20px;
  color: #222;
}

.gyld-logo {
  height: 35px;
  /* padding-top: 37px; */
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 520px;
  margin-right: 20px;
}
.rightSideDevelopers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2%;
}

.link {
  text-decoration: none;
  color: #222;
  font-size: 14px;
  text-transform: uppercase;
}
.link-popup {
  text-decoration: none;
}
.signUp {
  border: 0px;
  background-color: #282c34;
  border-radius: 12px;
}

.logIn {
  border: 1px solid #282c34;
  background-color: white;
  border-radius: 25px;
}
.button {
  padding: 10px;
}
.nav {
  border: 0px;
  background-color: transparent;
}
.nav:hover {
  background-color: #f1f1f1;
}
.signUpLink {
  color: white;
}

.profile-img {
  height: 56px;
  width: 56px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 50px;
}
@media (max-width: 800px) {
  .profile-img {
    height: 45px;
    width: 45px;
  }}



.green-dot {
  height: 14px;
  width: 14px;
  background: #32cd32;
  border: 1px solid #ffffff;
  border-radius: 50px;
  position: absolute;
  top: 50px;
  right: 45px;
  /* z-index: 1000; */
}
