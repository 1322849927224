
.popup-delete{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 90px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 640px;
  height: 252px;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.delete-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.no-button {
  border: 1px solid #000000;
  border-radius: 8px;
  margin-right: 20px;
  width: 145px;
  height: 40px;
}

.yes-button {
  color: #ffffff;
  background-color: #000000;
  border-radius: 8px;
  width: 145px;
  height: 40px;
}

.check-button {
  height: 24px;
  width: 24px;
  border: 1px solid #000000;
  border-radius: 8px;
  margin-right: 20px;
}

.close-icon {
  height: 40px;
  width: 40px;
}
