.popup-add-request {
  margin: auto auto;
  margin-top: 2%;
  height: 92%;
  width: 70%;
  border-radius: 24px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  outline: 0;
  background-color: rgb(255, 255, 255);
  padding: 5px 5px 20px 20px;
  max-height: 960px;
  max-width: 1500px;
}
@media(max-width:1200px){
  .popup-add-request { width: 97%;}}




.add-request-configurator-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  padding-bottom: 10px;
  padding-top: 10px;
}

.request-configurator-grey-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height:670px;
  border-radius: 24px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
}


.add-request-scroll {
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* height: 745px; */
}

.request-configurator-right {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  width: 70%;
  padding-top: 50px;
}

.add-reqest-height-width-input{
  height: 68px;
  width:85%;
}
.add-reqest-height-width-input-last{
  height: 68px;
  width:100%;
}

.add-request-width90p{
  width:90%
}

.request-configurator-right-overflow {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
}

.total-cost {
  color: #ffcc7f;
  font-family: Proxima Nova;
  font-weight: 700;
  font-size: 18;
  padding-left: 40px;
}

.config-blue {
  color: #7eafff;
}


.add-request-column-list-scroll{
  display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 100%;
height: 580px;
overflow-x: hidden;
overflow-y: scroll;
scrollbar-color: #c8c5c5 transparent;
scrollbar-width: thin;
padding-top: 20px;
}