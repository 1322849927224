.popup-add-contract {
    margin: 90px auto;
    padding: 10px 10px 15px 20px;
    background: #fff;
    border-radius: 24px;
    width: 45%;
    min-width: 650px;
    transition: all 0.5s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 850px;
    height: 50%;
    min-height: 350px;
    max-height: 550px;
  }
  @media (max-width: 1200px) {
    .popup-add-contract {
      width: 98%;
    max-width: 1000px;
  min-width: 400px;}}
  @media (max-width: 500px) {
    .popup-add-contract {
      min-width: unset;
      margin: auto auto;
      margin-top: 1%;
      height: 100%;
      max-height: 700px;
    }}

  .add-contract-width{
    width:45%;
  }
  @media (max-width: 500px) {
    .add-contract-width{
    width:100%;
  }}

  .add-contract-width-small{
    width:30%;
  } @media (max-width: 500px) {
    .add-contract-width-small{
    width:100%;
  }}


  .filename{
    max-width: 200px;
    
    overflow: hidden;
    text-overflow: ellipsis;
  
  }

  .add-contract-scroll{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: #c8c5c5 transparent;
    scrollbar-width: thin;
    padding-right: 3px;
  }

  .add-contract-row-to-column{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
  }

  @media (max-width: 500px) {
    .add-contract-row-to-column{
      display: flex;
flex-direction: column;
justify-content: space-between;

    }}


    .add-contract-row-start-center {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    @media (max-width: 500px) {
      .add-contract-row-start-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
      }}

