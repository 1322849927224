.messages-separator {

    width: 100%;
    padding: 0px 20px 25px 20px;
}

.create-new-message {
    margin-left: 10px;
    align-self: center;
    cursor: pointer;
    background-color: transparent;

}

.message-padding {
    padding: 10px 25px;
}

.messages-card {
    height: 430px;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #faf6ff;

}


.messages-list-back {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: white;
    margin-left: 20px;
    width: 50%;
    height: 92%;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
}


.messages-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #c8c5c5 transparent;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 20px;

}

/* @media (max-width: 1000px) { */
/* .messages-list {

        width: 100%;
        height: unset;
        /* flex-wrap: wrap; */



.message-image {
    height: 56px;
    width: 56px;
    margin-right: 25px;
    border-radius: 50px;
}

.body-message {
    height: 90% !important;
    outline: none;
    border: 1px solid lightgray;
    margin-top: 15px;
    overflow-y: scroll;
    resize: none;

}

.message-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
}

.select-to-user {
    /* padding:10px 14px; */
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-right-color: currentcolor;
    border-right-style: none;
    border-right-width: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
}


.message-content-back {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px 5px 20px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
}



.mobile-messages-card {
    height: max-content;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #faf6ff;
    padding: 20px 15px;

}

.mobile-messages-list-back {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: white;
    width: 100%;
    height: 250px;
    min-height: 250px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
}

.messages-mobile-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0px 10px 0px
}

.mobile-message-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
    min-height: 300px;
}


.mobile-message-content-back {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 15px 20px 5px 20px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.12);
    min-height: 300px;
}


.mobile-body-message {
    height: 90% !important;
    outline: none;
    border: 1px solid lightgray;
    margin-top: 10px;
    overflow-y: scroll;
    resize: none;
    min-height: 139px;

}