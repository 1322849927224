.contact-out {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 30px;
    border: 8px solid #f0f5ff;
    border-radius: 24px;
    margin-bottom: 5%;

}


.contact-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 1200px) {
    .contact-row-space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
}

.contact-margin-b20 {
    margin-bottom: 20px;
    align-self: flex-end;
}

.contact-input-width {
    width: 30%;
}

@media (max-width: 1200px) {
    .contact-input-width {
        width: 100%;
    }
}



.contact-captcha-width {
    width: 23%;
    min-width: 250px;
}

@media (max-width: 800px) {
    .contact-captcha-width {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .contact-captcha-width {
        width: 100%;
        min-width: 180px;
    }
}

.contact-captcha-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.contact-captcha-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 800px) {
    .contact-captcha-row-space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.contact-send-button {
    align-self: flex-end;
    margin-bottom: 13px;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .contact-send-button {
        margin-top: 15px;
        white-space: nowrap;
        max-width: 200px;
        margin-bottom: unset;
    }
}

@media (max-width: 500px) {
    .contact-send-button {
        align-self: center;
    }
}

