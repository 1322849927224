/* .navBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  padding: 50px;
} */

.platform-body {
  width: 70%;
  margin-left: 4%;
}

/* .leadFeed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  width: 900px;
  margin-top: 40px;
} */

.separate-area {
  margin-top: 20px;
  width: 100%;
}

.platform {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0% 1%;
  max-width: 1500px;
}
@media (max-width: 2000px) {
  .platform {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0% 2%;
    max-width: 1400px;
  }
}



.searchBar {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 16px 22px 16px 20px;
  font-family: "Lato", sans-serif;
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  width: 800px;
}

.searchInput {
  border: none;
  padding-left: 20px;
  width: 100%;
  outline: none;
}

.selectCategory {
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  width: 190px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
}

.searchButton {
  padding: 15px 15px 15px 15px;
  background-color: black;
  color: #fff;
  width: 200px;
  margin-top: 50px;
}

.searchButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.clearAll {
  margin-top: 50px;
  margin-left: 20px;
}

.opp-clearAll {
  padding-left: 20px;
}

.kpi-card-dash {
  align-self: center;
}

.kpi-card-dash-dev {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 1%;
}

/* .kpi-card-dash-admin {
  background-color: #fff;
  width: 300px;
  height: 110px;
  padding: 20px;
  margin-right: 10px;

  margin-top: 10px;
} */


.dash-row-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.card-finances {
  height: 430px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.card-lead-invoices {
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}


.dashboard-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 3%;
}
@media (max-width: 800px) {
  .dashboard-box {
    padding: 5%;
  }
}

.dashboard-box-out {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  height: 208px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
}

@media (max-width: 1200px) {
  .dashboard-box-out {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 100%;
  }
}

.dashboard-text-blue {
  font-family: "Proxima Nova";
  color: #767fad;
  font-size: 16px;
  text-align: center;
}

.dashboard-text-grey {
  font-family: "Proxima Nova";
  color: #656565;
  font-size: 16px;
}

.dashboard-number {
  font-family: "Lora";
  font-size: 32px;
  color: #323232;
}

.dash-img {
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 10px;
  height: 190px;
}
@media (max-width: 1200px) {
  .dash-img {
    margin-right: unset;
    margin-left: unset;
    height: 100%;
    width: 40%;
    min-width: 200px;
    max-width: 400px;
  }
}



.dash-line-disappear {
  height: 1px
}
@media (max-width: 1200px) {
  .dash-line-disappear {
    opacity: 0;
  }
}


.dash-line-between {
  height: 100%;
}
