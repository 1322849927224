.leads-basis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}



.kpi-background-accepted {
  background-color: rgb(123, 165, 174);
  border-radius: 8px;
  padding: 10px;
  margin-right: 20px;
}


.kpi-background-started {
  background-color: rgb(10, 136, 164);
  border-radius: 8px;
  padding: 10px;
  margin-right: 20px;
}





.leads-hints-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px 0px 20px 45px;
}

@media (max-width: 1200px) {
  .leads-hints-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  padding: 20px;
text-align: justify;}}

.card-hints {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  /* padding: 10px 0px 0px 50px; */
  /* width: 100%; */
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .card-hints {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px;
    border-radius: 24px;
    /* padding: 10px 0px 0px 50px; */
    /* width: 100%; */
    margin-top: 20px;
  }
}

.add-lead-button {
  position: relative;
  bottom: 0px;
  width: 150px;
  margin-top: 10px;
  padding: 5px;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-weight: 700;
  margin-bottom: 20px;
}

.add-lead-button-tmp {

  width: 135px;
 
  padding: 5px;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-weight: 700;
 
}

.hints-image {
  height: 450px;
  width: 120%;
  margin-left: -145px;
  /* margin-bottom: -10px; */
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
  margin-right: -30px;
}

@media (max-width: 1200px) {
  .hints-image {
width: 100%;
height: 330px;
margin-left: 0px;
margin-bottom: -10px;
border-bottom-right-radius: 0px;
border-top-right-radius: 24px;
margin-right: 0px;
margin-top: -30px;}}


.help-icon {
  position: relative;
  right: 24px;
  top: 24px;
  transition: all 200ms;
  color: #626da1;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .help-icon {
  right: -24px;}}

.leads-arrow-out {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .leads-arrow-out {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }}


.leads-arrow {
  margin-top: 1px;
  margin-right: 10px;
  margin-left: 10px;
  color: #0645ad;
}
@media (max-width: 1200px) {
  .leads-arrow {
   transform: rotate(90deg);
   margin-top: 10px;
   margin-bottom: 10px;
  }}

.heading-lora {
  font-family: "Lora";
  font-size: 20px;
  color: #323232;
  font-weight: 700;
}

.heading-lora22 {
  font-family: "Lora";
  font-size: 22px;
  font-weight: 700;
}

.text-Proxima Nova {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
}

.text-Proxima Nova700 {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 700;
}

.text-Proxima Nova-small {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
}

.text-Proxima Nova700-small {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 700;
}

.text-Proxima Nova700-extra-small {
  font-family: "Proxima Nova";
  font-size: 12px;
  font-weight: 700;
}

.lightgrey {
  color: #656565;
}

.darkgrey {
  color: #323232;
}



.leads-icons {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.path-card-highlighted {
  text-align: center;
  background-color: #fff;
  padding: 50px 20px 50px 20px;
  box-shadow: rgb(179, 177, 177) -5px 5px 5px 1px;
  width: 200px;
}
.path-card {
  text-align: center;
  background-color: #fbf8ff;
  padding: 50px 20px 50px 20px;
  width: 200px;
  box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px;
}
.path-triangle-highlighted {
  /* width: 0;
height: 0;
border-style: solid;
border-width: 0px 45px 90px 45px;
border-color: transparent transparent rgb(179, 177, 177) transparent; */
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-left-width: 25px;
  border-right-width: 25px;
  border-bottom-width: 50px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  rotate: 90deg;
  filter: drop-shadow(2px 0px 1px rgb(179, 177, 177));
  position: relative;
  left: -1px;
  top: 30%;
}
.path-triangle {
  /* width: 0;
height: 0;
border-style: solid;
border-width: 0px 45px 90px 45px;
border-color: transparent transparent rgb(179, 177, 177) transparent; */
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-left-width: 25px;
  border-right-width: 25px;
  border-bottom-width: 50px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  rotate: 90deg;
  filter: drop-shadow(1px 0px 1px rgb(179, 177, 177));
  position: relative;
  left: -1px;
  top: 30%;
}

.kpi-card-leads {
  height: 44px;
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  min-width: 200px;
}


.leads-background-grey {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .leads-background-grey {
    padding: 15px;}}


.leads-row-space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
align-items: center;
}


@media (max-width: 1200px) {
.kpi-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}}

@media (max-width: 1200px) {
.mobile-center{
  text-align: center;
}}

.add-button-background{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}