.calendarScreen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.calendar {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
}

.goBack {
  align-self: flex-start;
  margin-left: 20px;
  margin-top: 20px;
}
