.footer-black{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height:10%;
  background-color: black;
}

.footer-back {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  background-color: black;
  width: 100%;
  height: 100%;
  padding: 1% 10% 1% 10%;
  /* position: fixed;
  bottom: 0px; */
  z-index: 1000;
  max-width: 2000px;
  align-self: center;
}
@media (max-width: 850px) {
  .footer-back {
    background-color: black;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
}
@media (max-width: 350px) {
  .footer-back {
    background-color: black;
    width: 100%;
    /* height: 100%; */
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }
}

.footer-middle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:10px 0px;
  gap:20px;
  
}
@media screen and (max-width: 850px) {
  .footer-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0px;
  }
  
}

.home-end-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 850px) {
  .home-end-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

/* .R {
  color: #fff;
  font-size: 15px;
  padding-left: 5px;
} */

.C {
  margin-left: 20px;
 align-self: center;
  color: white;
}

.home-end-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 850px) {
  .home-end-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
