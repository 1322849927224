.opp-card-out {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 24px;
    padding: 15px 20px 15px 25px;
    height: 100%;
    min-height: 270px;
    margin-top: 20px;
  }
  @media (max-width: 800px) {
    .opp-card-out {
        height: 100%;
      padding: 15px 20px 15px 25px;
      width: 100%;
    }}
    @media (max-width: 500px) {
      .opp-card-out {height: 100%;
        padding: 15x;
        width: 100%;
      }}


      .opp-card-skills{
        display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 15px;
      flex-wrap: wrap;
    }

    .opp-card-row-2column{
      display: flex;
flex-direction: row;
justify-content: flex-start;
width: 100%;
    }
    @media (max-width: 1000px) {
      .opp-card-row-2column{
      display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
align-items: flex-start;
padding: 10px 0px;}}