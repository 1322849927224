.headerBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height:600px;
  padding: 5% 0% 0% 0%;
  /* flex-wrap: wrap; */
}

@media (max-width: 800px) {
  .headerBar {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px 10px 50px 10px;
    /* flex-wrap: wrap; */
  }
}
@media (max-width: 800px) {
  .headerBar {
    padding: 10px 0px 50px 0px;
  }
}


.leftSideHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: start;
  width: 50%;
  padding-top: 110px;
}

@media (max-width: 800px) {
  .leftSideHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 0px;
  }
}


.font {
  font-family: "Lato", sans-serif;
}
.carousel{
  max-width: 2000px;
  /* padding: 1% 10% 1% 10%; */
 
}
.clients-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 0px 0px 0px;
}
@media screen and (max-width: 800px) {
  .clients-left{

    width: 100%;
  }
  
}

.clients-section-desktop{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;


}
.clients-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;


}
@media screen and (max-width: 800px) {
  .clients-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  
}
.carousel-inner{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 0px;
}
.carousel-client{
  width:50%;
  display: flex ;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
}
  @media (max-width: 800px) {
    .carousel-client{
      width:100%;
    }
  
  }


.client-logo {
  max-width: 150px;
  max-height: 100px;
 
}
.client-logo-sm {
  max-width: 80px;
  max-height: 60px;
 
}
.client-logo-md {
  max-width: 100px;
  max-height: 80px;
 
}
.home-title {
  /* line-height: 72px; */
  text-align: left;
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 51px;
  color: #000000;
  padding-bottom: 20px;
}
@media (max-width: 800px) {
  .home-title {
    text-align: center;
  }
}

.home-subtitle {
  font-family: "Lora";
  font-size: 24px;
}

.home-subtitle22 {
  font-family: "Lora";
  font-size: 22px;
}

.home-text {
  font-size: 16px;
  line-height: 28px;
  /* font-family: Proxima Nova; */
  font-weight: 500px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #323232;
}
.image {
  width: 33% !important;
  min-width: 300px;
  pointer-events: none;
  /* margin-left: 20px; */
}
.carousel-buttons {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  /* box-shadow: 0px 2px 6px rgba(1, 1, 1, 0.24); */
  border-radius: 8px;
}
@media (max-width: 800px) {
  .carousel-buttons {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
    align-content: center;
  }}

.home-card-out {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 0px 0px 0px;
  justify-content: space-around;
}
@media (max-width: 1200px) {
  .home-card-out {
    display: flex;
    flex-direction: column;
    justify-items: center;
    /* flex-wrap: wrap; */
    width: 100%;
    padding: 0px;
    margin-top: 40px;
  }
}
@media (max-width: 800px) {
  .home-card-out {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 50px 10px 10px 10px;
  }
}

.home-card-two {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 80px;
}
@media (max-width: 1200px) {
  .home-card-two {
    display: flex;
    /* width: 50%; */
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    margin-bottom: 0px;
  }
}
@media (max-width: 800px) {
  .home-card-two {
    display: flex;
    /* width: 50%; */
    flex-direction: column;
    /* justify-content: space-around; */
  }
}

.home-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  /* padding: 30px; */
  width: 700px;
  height: 100%;
  min-height: 400px;
}
@media (max-width: 1200px) {
  .home-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 700px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    /* padding: 35px; */
    height: 100%;
    margin-bottom: 50px;
  }
}
@media (max-width: 800px) {
  .home-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    /* padding: 35px; */
    /* height: 100%; */
    margin-bottom: 20px;
    
  }
}

.home-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px 30px 30px;
  height: 100%;
}
@media (max-width: 1200px) {
  .home-card-inner {
    padding: 5px 30px 30px 30px;
    min-height: 260px;
  }
}
@media (max-width: 800px) {
  .home-card-inner {
    padding: 0px 20px 25px 20px;
  }
}

.card-image {
  width: 100%;
  height: 40%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 70px;
}

.home-card-title {
  font-family: "Lora";
  font-size: 35px;
  font-weight: 700;
}

.home-card-subtitle {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 800px) {
  .home-card-subtitle {
    font-size: 18px;
    line-height: 28px;
    padding: 10px 0px 10px 0px;
  }
}

.home-card-text {
  font-family: "Proxima Nova";
  font-weight: 600;
  font-style: italic;
}
.home-card-text-plain {
  font-family: "Proxima Nova";
  font-weight: 600;
}

.what-sign-up {
  border: none;
  box-shadow: none;
  color: blue;
}
.green-check-icon {
  color: green;
  /* margin-bottom: 10px; */
  margin-right: 10px;
}
.what-gyld {
  display: flex;
  flex-direction: row;
  /* margin-top: 100px; */
  flex-wrap: wrap;
  width: 100%;
  height:500px;
  margin-top: 17%;
}
@media (max-width: 1200px) {
  .what-gyld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
    padding-top: 100px;
    height: 25%;
    padding-bottom: 50px;
  }
}

.what-image {
  width: 50%;
  height: 100%;
}
@media (max-width: 1200px) {
  .what-image {
    width: 100%;
  }
}

.what-subtitle {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.what-text {
  font-size: 16px;
  line-height: 28px;
  font-family: "Proxima Nova";
  font-weight: 500px;
  /* line-height: 28px; */
  text-align: justify;
  /* letter-spacing: 0.02em; */
  color: #323232;
  padding-left: 34px;
  /* padding-bottom: 5%; */
}

.what-column-space-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height:100%;
  gap:10px;
}
@media (max-width: 1200px) {
  .what-column-space-between{
display: flex;
flex-direction: column;
justify-content: center;
width: 60%;
align-self: center;
padding-top: 3%;
}}
@media (max-width: 800px) {
  .what-column-space-between{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  align-self: center;
}}

.right-side-what-gyld {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4% 4% 4% 8%;
  background-color: #faf6ff;
  width: 50%;
  max-height: 100%;
gap: 10px;
}

@media (max-width: 1200px) {
  .right-side-what-gyld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 20px 40px 20px;
    width:100%;
    align-items: center;
  }
}
@media (max-width: 450px) {
  .right-side-what-gyld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 10px 40px 5px;
    width:100%;
    align-items: center;
  }
}

.what-gyld-end {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.cta {
  display: flex;
  background: #f5f5f5;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 17%;
  height: 350px;
}
@media (max-width: 800px) {
  .cta {
    display: flex;
    background: #f7faff;
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
    padding: 10px 10px 30px 10px;
    margin: 0px;
    margin-top: 18%;
    margin-bottom: 40px;
  }
}

.left-side-cta {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  padding: 3% 0% 3% 8%;
  align-items: flex-start;
}
@media (max-width: 800px) {
  .left-side-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: start;
    width: 100%;
    margin-right: 0px;
    padding: 10px;
  }
}

.button-black-small {
  border: 0px;
  border-radius: 8px;
  background-color: black;
  padding: 5px 25px 5px 25px;
  color: white;
  height: 38px;
  margin-right: 20px;
}
.button-black-small-nm {
  border: 0px;
  border-radius: 8px;
  background-color: black;
  padding: 5px 25px 5px 25px;
  color: white;
  height: 38px;
}

.button-black-big {
  border: 0px;
  border-radius: 8px;
  background-color: black;
  padding: 5px 15px 5px 15px;
  color: white;
  height: 48px;

}

.cta-button-black-big {
  border: 0px;
  border-radius: 8px;
  background-color: black;
  padding: 5px 15px 5px 15px;
  color: white;
  height: 48px;
  margin-top: 10px;

}
@media (max-width: 450px) 
{.cta-button-black-big {
  border: 0px;
  border-radius: 8px;
  background-color: black;
  padding: 5px 15px 5px 15px;
  color: white;
height: 67px;
width: 250px;

}}

.button-white-small {
  border: 1px solid black;
  border-radius: 8px;
  padding: 5px 25px 5px 25px;
  height: 38px;
}

.button-white-big {
  border: 1px solid black;
  border-radius: 8px;
  padding: 5px 15px 5px 15px;
  height: 48px;
  min-width: 50%;
}


.cta-image {
  margin-top: -12%;
  height: 155%;
}

@media (max-width: 800px) {
  .cta-image {
    margin-top: -26%;
    width: 30%;
    min-width: 300px;
    align-self: center;
  }
}

.roww {
  display: flex;
  flex-direction: row;
}

.roww-pad {
  display: flex;
  flex-wrap: wrap;
}

.text-align-left {
  text-align: left !important;
}
@media (max-width: 800px) {
  .text-align-left {
    text-align: justify !important;
  }
}





/* .arrow-down{

} */

.stepper-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 2% 3%;
  background-color: #f5f5f5;

}

.grey-back-home{
  background-color: #f5f5f5;
  border-radius: 24px;
}

.stepper-description{
  padding: 0px 70px;
text-align: center;
}
@media (max-width: 1000px) {
  .stepper-description{
    padding: 0px 0px;
  
  }
}
.unset-width-stepper-text{
  width: unset !important;
}

@keyframes stepper {
    from {background-color: transparent; }
    to {background-color:#cce2e1;}
  }
  @keyframes stepper-dot {
      from {height: 0px;width:0px; }
      to {height:7px;width:7px;}
    }
  @keyframes stepper-line {
      from {background-color: grey;}
      to {background-color: rgb(4, 166, 255);}
    }
@keyframes stepper-text-title {
    /*   from {color: grey;}
      to {color: rgb(4, 166, 255);} */
  }
@keyframes stepper-text {
  /*   from {color: grey;}
  to {color: rgb(4, 166, 255);}
   */
}
@keyframes stepper-number {
      from {color:#f5f5f5;}
    to {color: #323232;}
  }
@keyframes stepper-text-area {
    /*   from {  box-shadow: none; border: none;
      border-radius: 0px; background-color: grey;}
      to {  box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px; background-color: white;
        } */
}
.dot-stepper-1-1{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  animation-duration: 0.5s;
  animation-delay:1s;
align-self: center;
  animation-fill-mode: forwards;

  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-1-2{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-1-3{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay:2s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-2-1{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  animation-duration: 0.5s;
  animation-delay: 3s;
align-self: center;

/* margin-bottom:10px; */
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-2-2{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 3.5s;
  /*margin-bottom:10px;*/
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-2-3{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-3-1{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  animation-duration: 0.5s;
  animation-delay: 5s;
align-self: center;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-3-2{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 5.5s;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-3-3{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 6s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-4-1{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  animation-duration: 0.5s;
  animation-delay: 7s;
align-self: center;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-4-2{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 7.5s;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-4-3{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 8s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.dot-stepper-5-1{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  animation-duration: 0.5s;
  animation-delay: 9s;
align-self: center;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-5-2{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay:9.5s;
  animation-fill-mode: forwards;
  /*margin-bottom:10px;*/
   /* animation-timing-function:ease-in; */

}
.dot-stepper-5-3{
  height:0px;
  width:0px;
  border-radius: 5px;
  background-color: #cce2e1;
  animation-name: stepper-dot;
  align-self: center;
  animation-duration: 0.5s;
  animation-delay: 10s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}

.stepper-line-1{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;

  animation-fill-mode: forwards;
  
   /* animation-timing-function:ease-in; */

}
.stepper-line-2{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;
 animation-delay: 1s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.stepper-line-3{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;
 animation-delay: 3s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.stepper-line-4{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;
 animation-delay: 5s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.stepper-line-5{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;
 animation-delay: 7s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.stepper-line-6{
  height:80px;
  width:1px;
  background-color: #000000;
  animation-name: stepper-line;
  animation-duration: 2s;
 animation-delay: 9s;
  animation-fill-mode: forwards;
   /* animation-timing-function:ease-in; */

}
.stepper-number-1{
  animation-name: stepper-number;
  animation-duration: 1s;
  color:#f5f5f5;
  animation-fill-mode: forwards;
}
.stepper-number-2{
  animation-name: stepper-number;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:2.5s ;
  animation-fill-mode: forwards;
  color:#f5f5f5;
}
.stepper-number-3{
  animation-name: stepper-number;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:4.5s ;
  animation-fill-mode: forwards;
  color:#f5f5f5;
}
.stepper-number-4{
  animation-name: stepper-number;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:6.5s ;
  animation-fill-mode: forwards;
  color:#f5f5f5;
}
.stepper-number-5{
  animation-name: stepper-number;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay:8.5s ;
  color:#f5f5f5;
}
.stepper-number-6{
  animation-name: stepper-number;
  animation-duration: 1s;
  animation-delay:10.5s ;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  color:#f5f5f5;
}

.stepper-text-area{

  display:flex;
   /* padding:10px; */ 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:70%;
padding: 20px;
 
 
  
}

.stepper-text-area-1{
  border-radius: 25px;
  display:flex;
   /* padding:10px; */ 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
 height:130px;
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
 
  
}
@media (max-width: 1200px) {
  .stepper-text-area-1{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-1{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 
.stepper-text-area-2{
  display:flex;
  border-radius: 25px;
   /* padding:10px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
 height:130px;
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
  animation-delay:2s
}
@media (max-width: 1200px) {
  .stepper-text-area-2{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-2{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 
.stepper-text-area-3{
  display:flex;
  border-radius: 25px;
   /* padding:10px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
 height:130px;
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
  animation-delay:4s
}
@media (max-width: 1200px) {
  .stepper-text-area-3{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-3{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 

.stepper-text-area-4{
  display:flex;
   /* padding:10px; */
  border-radius: 25px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
 height:130px;
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
  animation-delay:6s
}
@media (max-width: 1200px) {
  .stepper-text-area-4{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-4{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 

.stepper-text-area-5{
  display:flex;
  border-radius: 25px;
   /* padding:10px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
 height:130px;
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
  animation-delay:8s
}@media (max-width: 1200px) {
  .stepper-text-area-5{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-5{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 

.stepper-text-area-6{
  display:flex;
   /* padding:10px; */
  border-radius: 25px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
width:900px;
  height:130px; 
  animation-name: stepper-text-area;
  animation-duration: 2s;
  animation-timing-function:ease-in-out;
  animation-delay:10s
}
@media (max-width: 1200px) {
  .stepper-text-area-6{
    width: 600px;
  }
  } 
  @media (max-width: 800px) {
    .stepper-text-area-6{
     width:100%;
height:300px;
margin-bottom:10px;
    }
    } 



.stepper-text-title-1{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

}
.stepper-text-title-2{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.stepper-text-title-3{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}
.stepper-text-title-4{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 6s;
}
.stepper-text-title-5{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}
.stepper-text-title-6{
  animation-name: stepper-text-title;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 10s;
}
.stepper-text-1{
  font-size: 14px;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.stepper-text-2{
  font-size: 14px;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.stepper-text-3{
  font-size: 14px;
  animation-fill-mode: forwards;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 4s;
}
.stepper-text-4{
  font-size: 14px;
  animation-fill-mode: forwards;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 6s;
}
.stepper-text-5{
  font-size: 14px;
  animation-fill-mode: forwards;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 8s;
}
.stepper-text-6{
  font-size: 14px;
  animation-fill-mode: forwards;
  animation-name: stepper-text;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 10s;
}
.number-div-1{
  height: 50px;
  width:50px; 
  animation-fill-mode: forwards;
  /* width: 100px; */
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  align-self: center;
  /* animation-timing-function: ease-in-out; */
  
}
.number-div-2{
  height: 50px;
  width:50px; 
  animation-fill-mode: forwards;
  /* width: 100px; */
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:2.5s ;
  align-self: center;
}
.number-div-3{
  height: 50px;
  width:50px; 
  animation-fill-mode: forwards;
  /* width: 100px; */
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:4.5s ;
  align-self: center;
}
.number-div-4{
  height: 50px;
  width:50px; 
  border-radius: 50px;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay:6.5s ;
  align-self: center;
}
.number-div-5{
  height: 50px;
  width:50px;  
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay:8.5s ;
  align-self: center;
}
.number-div-6{
  height: 50px;
  width:50px; 
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation-name: stepper;
  animation-duration: 1s;
  animation-delay:10.5s ;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  align-self: center;
}
.home-stepper-area{
  width: 80% !important;
margin-left: 20%;

}
@media (max-width: 500px) {
  .home-stepper-area{
    width: 100% !important;
  margin-left: 10px;
  margin-top:20px;
  
  }
}
@media (max-width: 1700px) {
  .home-stepper-area{
    width: 100% !important;
  margin-left: 20%;
  margin-top:20px;
  
  }
}
.stepper-icons{
  /* height:880px; */
  width: 70px !important;
  margin-right: 65px;
}
/* @media (max-width: 400px) {
  .home-stepper-area{
    height:1200px;
    margin-right: 10px;
    margin-bottom:20px;
  }
}
@media (max-width: 500px) {
  .home-stepper-area{
    height: 950px;;
    margin-right: 10px;
    margin-bottom:20px;
  }
} */
@media (max-width: 800px) {
  .stepper-icons{
   
    margin-right: 10px;
   
  }
}
.home-stepper-icon-section{
height:50px;
display:flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
align-self: center;
margin-top:10px;
}
@media (max-width: 800px) {
  .home-stepper-icon-section{
   
    height:70px;
    margin-top:20px;
   
  }
}
@media (max-width: 500px) {
  .home-stepper-icon-section{
   
    height:140px;
    margin-top:20px;
   
  }
}
.unset-width{
  width:unset !important;
  margin-right: 65px;
}

.line-vert{
  height:80px;
 width: 1px;
 align-self: center;
}

.margin-b78{
  margin-bottom: 78px;
}




.home-align-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}