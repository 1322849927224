.popup-add-client{
    margin:auto auto;
    margin-top: 3%;
padding: 5px 20px 20px 20px;
background: #fff;
border-radius: 24px;
width: 45%;
max-height: 90%;
transition: all 0.5s ease-in-out;
overflow-y: auto;
overflow-x: hidden;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
max-width: 880px;
}