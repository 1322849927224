.card {
  /* background: #ebebeb; */
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
}


.profile-img-small {
  height: 40px;
  width: 40px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 50px;
}

.no-pic-small {
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.jobs-card-out {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 24px;
  padding: 15px 20px 15px 25px;
  height: 100%;
  min-height: 270px;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .jobs-card-out {
    height: 100%;
    padding: 15px 20px 15px 25px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .jobs-card-out {
    height: 100%;
    padding: 15px;
    width: 100%;
  }
}


.job-card-row-2column {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1200px) {
  .job-card-row-2column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
    padding: 10px 0px;
  }
}


.job-card-hide-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-left: 20px;
  align-self: center;
}

@media (max-width: 1200px) {
  .job-card-hide-icon {
    align-self: flex-start;
  }
}


.job-card-won-icon {
  margin-top: 2px;
}



.job-card-simulate-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-left: 20px;
  align-self: flex-start;
}

.job-card-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 550px) {
  .job-card-row-space-between {
    flex-direction: column;
  }
}