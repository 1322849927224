.orga-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border: 8px solid #f0f5ff;
  border-radius: 24px;
  padding: 2% 3%;
}
@media (max-width: 1200px) { 
.orga-profile-card{
  align-items: center;
  padding: 5% 10%;
}}

.orga-row-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width:100%
}
@media (max-width: 1200px) { 
  .orga-row-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  align-items: center;
    height: 100%;
    width:100%
  }}


.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
  width: unset !important;
}

.profile-box-height-short1{
  height:80px;
  width:85%;
}
@media (max-width: 1200px) { 
  .profile-box-height-short1{
    width:100%;
  }}

.profile-box-height-short2{
  height:80px;
  width:42.5%;
}
@media (max-width: 1200px) { 
  .profile-box-height-short2{
    width:100%;
  }}

.profile-box-height-long{
  height:75px;
  width:100%;
}
@media (max-width: 1200px) { 
  .profile-box-height-long{
    height:unset;

  }}

.one-third-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
}
@media (max-width: 1200px) { 
  .one-third-column{
    width: 100%;
  }}

.two-thirds-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 66%;
}
@media (max-width: 1200px) { 
  .two-thirds-column{
    width: 100%;
  }}

.orga-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1200px) { 
  .orga-row-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }}


  .orga-users {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    padding: 2% 3%;
    margin-top: 50px;
    height: max-content;
  }
  @media (max-width: 1200px) {
    .orga-users {
      padding: 5% 10%;
    }}

  .orga-row-users {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  @media (max-width: 1200px) {
    .orga-row-users {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   height: 16vh;
    }}

    .users-boxes-width{
width:28.3%;
    }
    @media (max-width: 1200px) {
      .users-boxes-width{
width:100%;
      }}