.popup-report {
  margin: auto auto;
  margin-top:3%;
  padding: 10px 5px 10px 20px;
  background: #fff;
  border-radius: 24px;
  width: 30%;
  height: 88%;
  transition: all 0.5s ease-in-out;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 800px;
  max-height:1400px;
  outline: 0;
}
@media (max-width: 1200px) {
  .popup-report {
    margin-top: 2%;
    padding: 15px 5px 15px 15px;
    width: 98%;
    height: 95%;

  }}



.popup-report .content {
  padding: 20px;
}


.img-face{
  height:77px;
  width: 77px;
}
@media (max-width: 1200px) {
  .img-face{
    height:50px;
    width: 50px;
  }}