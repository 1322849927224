

.kpi-background-accepted {
  background-color: rgb(123, 165, 174);
  border-radius: 8px;
  padding: 10px;
  margin-right: 20px;
}


.kpi-background-started {
  background-color: rgb(10, 136, 164);
  border-radius: 8px;
  padding: 10px;
  margin-right: 20px;
}




.opp-button {
  color: #0645ad;
  font-family: "Proxima Nova";
  text-decoration-line: underline;
  font-size: 18px;
  font-weight: 700;
}

.opp-img {
  border-top-right-radius: 24px;
  height: 420px;
  margin-right: -30px;
}
@media(max-width:800px){
.opp-img{
margin-top: -30px;}}

.opp-arrow-out {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
  .opp-arrow-out {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }}

.arrow {
  margin-top: 1px;
  margin-right: 10px;
  margin-left: 10px;
  color: #0645ad;
}
@media (max-width: 1200px) {
  .arrow {
   transform: rotate(90deg);
   margin-top: 10px;
   margin-bottom: 10px;
  }}

.bulb {
  width: 22px;
  height: 40px;
  margin-right: 10px;
}

.light-grey-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #f5f5f5;
  border-radius: 24px;
  margin-top: 50px;
  padding: 30px;
}
@media (max-width: 1200px) {
  .light-grey-box {
    padding: 20px;}}

.opp-box-heading {
  font-family: "Lora";
  font-size: 20px;
  font-weight: bold;
}

.opp-boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
  border-radius: 24px;
  padding: 5px 20px 2px 30px;
  height: 300px;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .opp-boxes {
    justify-content: space-between;
    height: 100%;
    padding: 20px 20px 20px 25px;
    width: 100%;
  }}
  @media (max-width: 450px) {
    .opp-boxes {
      padding: 15px;
    }}

  .opp-boxes-readmore {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 24px;
    padding: 20px 35px 2px 30px;
   
    margin-top: 20px;
  }

.opp-grey {
  color: #323232;
}

.opp-lightgrey {
  color: #656565;
}

.opp-status {
  font-family: "Proxima Nova";
  font-size: 14px;
  color: #767fad;
}

.ball {
  background-color: #0645ad;
  border-radius: 44px;
  height: 6px;
  width: 6px;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
}

@media(max-width:800px){
  .ball{
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

.opp-search-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 550px) {
  .opp-search-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;
  }}

.opp-box-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  /* margin-bottom: -13px; */
}
@media (max-width: 1200px) {
.opp-box-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
width: 100%;
align-items: flex-start;
padding: 10px 0px;

}}

.opp-box-row-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 1200px) {
.opp-box-row-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding:10px 0px;
  flex-wrap: wrap;
}}

.opp-box-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
@media (max-width: 1200px) {
  .opp-box-row-end {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }}

.opp-box-button {
  background-color: #eeeeee;
  border-radius: 12px;
  padding: 5px 15px;
  margin-right: 20px;
}
@media (max-width: 800px) {
.opp-box-button {
margin-bottom: 15px;
}}

.opp-box-end-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: #626da1;
}

.opp-box-end-end{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  }
  @media (max-width: 1200px) {
    }
    .opp-box-end-end{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      }

.opp-box-icon-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 9%;
  align-self: end;
}

.opp-box-text-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  align-self: end;
}

.opp-margin {
  margin-right: 20px;
}
/* @media (max-width: 800px) {
  .opp-margin {
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }} */


.opp-searchBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 16px 22px 16px 20px;
  font-family: Proxima Nova;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  width: 80%;
  min-width: 200px;
  height: 50px;
}
@media (max-width: 800px) {
.opp-searchBar {
padding: 10px 22px 10px 7px;
height: 50px;
margin-top: 10px;
width:100%;
}}

.opp-categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  /* border: 0.5px solid #e0e0e0; */
  border-radius: 16px;
  background: #ffffff;
  height: 75px;
  padding: 20px;
  flex-wrap: wrap;
}


.mob-opp-categories {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border-radius: 16px;
  background: #ffffff;
  height: 100%;
  padding: 20px;
}

.opp-selectCategory {
  width: 220px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  height: 40px;
  font-family: Proxima Nova;
  color: #bdbdbd;
}
@media (max-width: 800px) {
  .opp-selectCategory {
    width: 120px;
    margin-bottom: 15px;
  }}



.circle-left {
  /* position: relative;
  bottom: 5px;
  right: 15px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-left: 20px;
  /* align-self: center; */
}

.circle-right {
  /* position: relative;
  bottom: 5px;
  right: -10px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-left: 20px;
  /* align-self: center; */
}
@media (max-width: 1200px) {
  .circle-right {
    align-self: unset;
  }}


  .top-circle-right {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    margin-left: 20px;
    align-self: center;
  }
  @media (max-width: 550px) {
    .top-circle-right {
    margin-left: 0px;}}

.opp-categories-box-width{
  width:20%
}

.mob-opp-categories-box-width{
  width:45%
}
@media (max-width: 450px) {
  .mob-opp-categories-box-width{
    width:100%
  }}

.opp-row-s-b-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 550px) {
  .opp-row-s-b-c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }}


  .opportunity-row-s-b {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 450px) {
    .opportunity-row-s-b {
    display: flex;
    flex-direction: column;}}