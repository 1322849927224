.popup-pub-profile {
  margin: auto auto;
  margin-top: 2%;
  padding: 0px 5px 20px 20px;
  background: #fff;
  border-radius: 24px;
  width: 100%;
  height: 80%;
  max-height: 750px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 700px;
  max-height: 700px;
  outline: 0;
}
@media (max-width: 1200px) {
  .popup-pub-profile {
    width: 98%;
    height: max-content;
    min-width: unset;
    padding: 0px 20px 20px 20px;
  }}


  .pub-profile-outline {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    border: 8px solid #f0f5ff;
    border-radius: 24px;
    padding: 25px 5px 30px 40px;
    margin-top: 20px;
    height: max-content;
  }
  @media (max-width: 400px) {
    .pub-profile-outline {
    display: flex;
    flex-direction: column;
    }}


.pub-profile-scroll{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #c8c5c5 transparent;
  padding-right: 10px;
  height: 520px;
}


.pub-prof-outline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border: 8px solid #f0f5ff;
  border-radius: 24px;
  padding: 20px 30px;
  margin-top: 20px;

}

.popup-pub-profile-client {
  margin: auto auto;
  margin-top: 3%;
  padding: 20px;
  background: #fff;
  border-radius: 24px;
  width: 40%;
  max-width: 800px;

  height: 70%;
  max-height: 700px;
  min-height: 400px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  outline: 0;
}

/* .pub-profile-client-out {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
height: 100%;
width: 100%;
padding: 0% 3%;
} */



.pub-prof-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  align-self: flex-end;
}
@media (max-width: 550px) {
  .pub-prof-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }}

.pub-prof-left-down {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.public-profile-add-skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  /* padding: 30px 40px;
  margin-top: 50px; */
  height: max-content;
}

/* .column-skills {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
} */


.trashbin {
  cursor: pointer;
  color: #626da1;
}

.pub-prof-close{
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
cursor: pointer;
color: black;
align-self: flex-end;
margin-right: 20px;
  
}


.overflow-pub-prof {
  height: max-content;
 overflow-y: scroll;
scrollbar-color: #c8c5c5 transparent;
scrollbar-width: thin;
overflow-x: hidden;
padding-right: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
/* align-self: flex-end; */
}