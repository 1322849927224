
  .page-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  }
  
  .blog-item {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:30%;
    box-shadow: 0 2px 6px rgba(0,0,0,.25);
    padding: 20px 30px 30px;
    max-height: 420px;
  }

  @media screen and (max-width: 800px) {
    .blog-item {
      width: 80%;
      height:380px;
    }
    
  }
  
  .blog-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .blog-link:hover {
    background-color: #f0f0f0;
  }
  
  .blog-title {
    font-size: 1.5em;
    margin: 0 20px 0 0;
  }
  
  .blog-image-preview {
    width: auto;
    max-height: 200px;
    border-radius: 10px;
  }
  