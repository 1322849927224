.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms ease-in-out;
}




.popup {
  margin: 90px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 60%;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.popup-leads {
  margin: auto auto;
  margin-top: 2%;
  height: 91%;
  width: 70%;
  border-radius: 24px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  outline: 0;
  background-color: rgb(255, 255, 255);
  padding: 5px 5px 20px 20px;
  max-height: 960px;
  max-width: 1500px;
}
@media(max-width:1200px){
  .popup-leads { width: 97%;}}

.add-lead-scroll {
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

}

.lead-configurator-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  padding-bottom: 2%;
}


.lead-configurator-grey-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height:670px;
  border-radius: 24px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.lead-configurator-bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
  height: max-content;
  /* border-right: 1px solid #bdbdbd; */
  padding: 0% 1% 1% 1%;

  max-height: 600px;

}

.lead-configurator-bottom-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-left: 1px solid #bdbdbd;
}


.add-lead-column-list-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 580px;
  overflow-x: hidden;
  /* overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin; */
  padding-top: 20px;
  /* padding-right: 2%; */

}

/* .popup h2 {
  margin-top: 0;
  color: #000;
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 18px;
} */


.skill-chips{
  display:flex;
  justify-content: flex-start;
  align-content: flex-start;
  height:150px;
  flex-wrap: wrap;
}

.add-lead-reload-icon {
  font-size: 25px !important;
  margin-left: 10px;
  align-self: center;
}

.close-add-lead {
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(0, 0, 0);
  cursor: pointer;
}


.lead-configurator-width {
  width: 90%
}

.lead-configurator-width-small {
  width: 90%
}


.popup .close:hover {
  cursor: pointer;
  color: #000;
}

.reset-button {
  color: #0645ad;
  margin-left: 20px;
  font-family: Proxima Nova;
  font-weight: 700;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}

.button-save-lead {
  background-color: #fff;
  border: 0px;
  border-radius: 8px;
  padding: 5px 25px 5px 25px;
  height: 38px;
}

.add-lead-list-row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  background-color: #fff;
  padding: 1.5%;
  border-radius: 20px;
  margin-bottom: 20px;
}




.leads-full-width {
  width: 100%
}

.leads-half-width {
  width: 48%;
  min-width: 140px;
}
@media(max-width:500px){
  .leads-half-width {
    width: 100%;
    min-width: 140px;
  }}

.lead-total-cost {
  color: #ff6210;
  font-family: "Proxima Nova";
  font-weight: 700;
  font-size: 18px;
  align-self: center;
}



.mob-popup-leads {
  margin: auto auto;
  margin-top: 1%;
  height: 97%;
  width: 97%;
  border-radius: 24px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  outline: 0;
  background-color: rgb(255, 255, 255);
  padding: 10px 7px 10px 20px;
}

.mob-add-lead-scroll {
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.leads-mob-row-to-column{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media(max-width:500px){
  .leads-mob-row-to-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }}

.mob-lead-grey-bottom{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 24px;
  padding: 10px 20px;
  border: 1px solid #eeeeee;
box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
margin-top: 20px;
}
@media(max-width:500px){
  .mob-lead-grey-bottom{
    padding: 10px;
  }}

.mob-add-lead-resources {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border-top: 1px solid #bdbdbd;
  padding-top: 15px;
  margin-top: 15px;
}

.mob-leads-end{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
width: 100%;
padding-top: 10px;
}

.lead-card-button-row{
display: flex;
flex-direction: row;
justify-content: flex-start;
width: 100%;
}