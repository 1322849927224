.hidden-out-opp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  height: 76px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-position: right;
  background-size: 62%;
  background-repeat: no-repeat;
  padding: 20px;
}

/* .hidden-add-lead-button {
  width: 150px;
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  font-family: Proxima Nova;
  font-weight: 700;
}

.hidden-hints-icon {
  height: 28px;
  width: 28px;
}

.hidden-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  align-items: center;
} */


.opp-mob-hidden-out {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  height: 74%;
  margin-top: 20px;
  background-position: top right !important;
  background-size: 101%;
  background-repeat: no-repeat;
  padding: 20px;
}