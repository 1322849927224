
  .page-title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }

  .blog-main-image {
    display: block;
    max-width: 100%;
    max-height: 600px;
    height: auto;
    margin: 20px 0px;
    border-radius: 10px;
    align-self: center;
  }
  
  .blog-content {
    text-align: center;
  }
  
  .blog-read-more {
    display: inline-block;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .blog-read-more:hover {
    background-color: #444;
  }
  
  pre {
    background: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  code {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    color: #c7254e;
    background-color: #f9f2f4;
    padding: 2px 4px;
    border-radius: 4px;
  }