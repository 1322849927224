
.request-img{
    border-bottom-right-radius: 24px;
    margin-top: 20px;
    width:400px;
    margin-right: 20px;
    height: 260px;
    
}
@media (max-width: 1200px) {
    .request-img{
        border-bottom-right-radius: 0px;
        align-self: center;
        margin-right: unset;
   
    }}


.req-card-hints {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    margin-top: 20px;
    align-items: flex-end;
  }
  @media (max-width: 1200px) {
    .req-card-hints {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      width: 100%;
      box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px;
      border-radius: 24px;
      margin-top: 20px;
    }
  }

  .kpi-card-requests {
    /* width: 19%; */
    height: 44px;
    padding: 10px;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;
    min-width: 200px;
    flex-wrap: wrap;
  }


  .req-green{
    color: #53bb50;
  }

  .req-blue{
    color: #547ee3;
  }
.req-purple{
  color: #9365d5;}

  .req-turquoise{
    color:#6cced2;
  }

.req-red{
  color: #b04773;
}

.req-orange{
  color: #d9906d;
}

.req-hide-icon{
position: relative;
right: 24px;
top: 24px;
color: #626da1;
cursor: pointer;
align-self: flex-start;
}
  @media (max-width: 1200px) {
    .req-hide-icon{
position: relative;
right: 24px;
top: 24px;
transition: all 200ms;
color: #626da1;
cursor: pointer;
align-self: flex-end;
}}

.req-add-button-background{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 15px;
}
@media (max-width: 1200px) {
  .req-add-button-background{
    margin-left: 15px;
  }}
@media (max-width: 500px) {
  .req-add-button-background{
    flex-direction: column;
    align-items: flex-start;
  }}
