
.popup-select{
margin: auto auto;
margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 5px 20px;
  background: #fff;
  border-radius: 24px;
  width:30%;
  height:30%;
  transition: all 0.5s ease-in-out;
  overflow-x: hidden;
  outline: 0;
  min-height:285px;
  max-width: 800px;
}

.select-appli-scroll{
  overflow-y: scroll;
scrollbar-color: #c8c5c5 transparent;
scrollbar-width: thin;
overflow-x: hidden;
padding-right: 5px;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
height: 100%;
}

.select-appli-column{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
width: 100%;
align-items: flex-start;
margin-left: 3%;
padding-top: 3%;
}


.MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label{
  padding-top: 4px;
}