.popup-select-candidates {
    margin: auto auto;
 margin-top: 2%;
    background: #fff;
    border-radius: 24px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 700px;
    max-height: 600px !important;
    height: 70%;
    outline: 0;
  }

  @media (max-width: 1200px) {
    .popup-select-candidates {
      width: 98%;
      height: max-content;
      min-width: unset;
      
    }}

  .select-cand-overflow{
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  .header-icon-color-star{
    cursor: pointer;
    color: yellow;
    width: 30px;
  
  }


.approve-candidate-button-black{
  border: 0px;
border-radius: 8px;
background-color: black;
padding: 5px 25px 5px 25px;
color: white;
height: 38px;}

@media (max-width: 368px) {
  .approve-candidate-button-black{
  padding: 5px;
}}
@media (max-width: 327px) {
  .approve-candidate-button-black{
  height: max-content;
}}


.select-candi-to-pub-prof{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 2px 30px 20px;
  margin: auto auto;
  margin-top: 3%;
  background: #fff;
  border-radius: 24px;
  overflow-y: hidden;
  overflow-x: hidden;
  width:100%;
  max-width: 700px;
  min-width: 550px;
  max-height: 80%;
  height: max-content;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
@media (max-width: 800px) {
  .select-candi-to-pub-prof{
    width: 98%;
    min-width: unset;
  }}
  @media (max-width: 400px) {
    .select-candi-to-pub-prof{
      height: max-content;
    }}

    .padding-pub-prof-top{
      padding: 5px 17px 0px 0px
    }