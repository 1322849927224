.projects-background-grey {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 50px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .projects-background-grey {
    padding: 15px;

  }
}


.projects-search-bar {
  width: 100%;
}


.projects-search-bar-contract {
  width: 100%;
}


.report-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: #fff;
  border-radius: 24px;
  padding: 15px;
  width: 30%;
  min-width: 250px;
  min-height: 100px;
}

@media(max-width:1200px) {
  .report-card {
    margin-bottom: 10px;
    width: 50%;
  }
}




.kpi-progect-nav {
  padding: 5px;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  display: "flex";
  flex-direction: row;
}

.kpi-margin {
  margin-right: 20px;
}

@media(max-width:1500px) {
  .kpi-margin {
    margin-right: 10px;
  }
}

.MuiTableContainer-root .MuiPaper-root {
  scrollbar-width: auto;
}

.kpi-card-project {
  background-color: #fff;
  width: 33vw;
  height: 80px;
  padding: 20px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.image-reports {
  height: 70%;
}

.row-start-center-width {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 10%;
}

.projects-row-start-2center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

@media(max-width:1200px) {
  .projects-row-start-2center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.projects-img {
  height: 320px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-left: 20px;
}

@media(max-width:1200px) {
  .projects-img {
    min-width: 300px;
    width: 45%;
    align-self: center;
    margin-left: unset;
  }
}


.margin-kpi-projects {
  margin-right: 8px;
}

@media(max-width:800px) {
  .margin-kpi-projects {
    margin-right: 0px;
  }
}

.selectProjects {
  width: 24%;
  cursor: pointer;
}
@media(max-width:1200px) {
  .selectProjects {
    width: 50%;
  }
}
@media(max-width:800px) {
  .selectProjects {
    width: 100%;
  }
}

.light-orange {
  background-color: #fffaf6;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding: 20px;
}

.grey-background {
  background-color: #f5f5f5;
  width: 100%;
  padding: 20px;
}

.white-hack {
  background-color: #fff;
  border-radius: 24px;
}

.projects-turquoise {
  color: rgb(4, 185, 185);
}

.projects-blue {
  color: rgb(15, 39, 147);
}

.projects-green {
  color: rgb(2, 145, 67);
}

.projects-red {
  color: rgb(198, 8, 21);
}

.projects-yellow {
  color: rgb(206, 169, 58);
}

.kpi-card-projects {
  height: 44px;
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  min-width: 200px;
}

@media (max-width: 1500px) {
  .kpi-card-projects {
    min-width: 160px;
  }
}


.projects-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}


.projects-searchBar {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 16px 22px 16px 20px;
  font-family: "Lato", sans-serif;
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  width: 70%;
}

@media (max-width: 1200px) {
  .projects-searchBar {
    width: 100%;
  }
}


.projects-row-center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .projects-row-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}


.projects-row-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

}

@media (max-width: 550px) {
  .projects-row-top {
    display: flex;
    flex-direction: column;
  }
}


.reports-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 1200px) {
  .reports-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
}

.projects-half-percent-width {
  width: calc(50% - 10px);
}

@media (max-width: 550px) {
  .projects-half-percent-width {
    width: 100%;
  }
}


.projects-phase-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width:1200px) {
  .projects-phase-card-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
}

.projects-phaescard-half {
  width: calc(50% - 10px);
}

@media (max-width: 1200px) {
  .projects-phaescard-half {
    width: 100%;
    margin-bottom: 15px;
  }
}


.escalated-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 120px;
}