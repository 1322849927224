.finances-card {
  padding: 50;
  background-color: #fff;
  border-radius: 3px;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.tspan{
  color:red
}

