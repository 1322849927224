.pricingBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30%;
  /* padding: 0% 10% 0px 10%; */
  flex-wrap: wrap;
}
@media (max-width: 800px) {
  .pricingBar {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30%;
    padding: 0% 10% 0px 10%;
    flex-wrap: wrap;
  }
}
@media (max-width: 450px) {
  .pricingBar {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30%;
    padding: 0px;
    flex-wrap: wrap;
  }
}

.pricing-leftSideHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}

@media (max-width: 800px) {
  .pricing-leftSideHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 50px;
  }
}
.pricing-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 100px;
  width: 100%;
}
@media (max-width: 1200px) {
  .pricing-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
}

.pricing-cards-out {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  height:550px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  border: 1px solid #eee;
  min-width: 200px;
}
@media (max-width: 1200px) {
  .pricing-cards-out {
    width: 60%;
    height:550px;
    margin-bottom: 20px;
    min-width: 200px ;
  }}
  @media (max-width: 800px) {
    .pricing-cards-out {
      width: 100%;
      margin-bottom: 20px;
      min-width: 200px;
    }}

.pricing-cards-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  background-color: #f5f5f5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}


.pricing-cards-bottom{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 30px 20px 20px 20px;
}


.pricing-cta {
  display: flex;
  background: #f7faff;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  width: 100%;
  margin-bottom: 75px;
  margin-top: 130px;
  height: 350px;
  padding-right: 28px;
}
@media (max-width: 1000px) {
  .pricing-cta {
    display: flex;
    background: #f7faff;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    background-color: whitesmoke;
    /* width: 100%; */
    margin-bottom: 75px;
    margin-top: 310px;
    height: 100%;
    padding-right: 0px;
  }
}


.pricing-one-click-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 63%;
  padding-left: 5%;
}
@media (max-width: 1000px) {
  .pricing-one-click-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 85%;
    padding: 20px;
  }
}
@media (max-width: 450px) {
  .pricing-one-click-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    padding: 20px 10px;
  }
}


.pricing-end-image {
  width: 385px;
  margin-top: -138px;
  padding-left: 20px;
}
@media (max-width: 1200px) {
  .pricing-end-image {
    width: 350px;
    margin-top: -118px;
    padding-left: 0px;
    max-width: 360px;
  }
}
@media (max-width: 1000px) {
  .pricing-end-image {
    width: 60%;
    margin-top: -290px;
    padding-left: 0px;
    min-width: 250px;
  }
}

.padding-t50 {
  padding-top: 50px;
}
