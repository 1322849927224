.jobs-img {
  height: 321px;
  margin-top: 12px;
  max-width: 550px;
}

@media(max-width:1200px) {
  .jobs-img {
    min-width: 300px;
    width: 45%;
    align-self: center;
    margin-top: unset;
  }
}

.kpi-card-jobs {
  /* width: 19%; */
  height: 44px;
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  min-width: 190px;
  /* margin-right: 20px; */
}

@media(max-width:1500px) {
  .kpi-card-jobs {
    min-width: 150px;
  }
}


.row-lightblue {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #f6f9ff;
  border-bottom: 1px solid #bdbdbd;
  height: 100px;
  padding: 20px 30px;
  margin-top: -40px;
}

@media(max-width:800px) {
  .row-lightblue {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 20px 10px;
  }
}

.row-lightblue-jobs-client {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #f6f9ff;
  border-bottom: 1px solid #bdbdbd;
  border-top: 1px solid #bdbdbd;
  height: 100px;
  padding: 20px 30px;
}

@media(max-width:800px) {
  .row-lightblue-jobs-client {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 20px 10px;
  }
}

.jobs-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

@media(max-width:800px) {
  .jobs-row-start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}


.jobs-icons {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

@media(max-width:800px) {
  .jobs-icons {
    margin-right: 0px;
  }
}



.jobs-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media(max-width:1200px) {
  .jobs-row-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media(max-width:1200px) {
  .mob-jobs-search-margin {
    margin-top: 20px;
  }
}

.jobs-white-back {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.client-jobs-white-back {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.jobs-card-hints {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-top: 20px;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .jobs-card-hints {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

.jobs-help-icon {
  position: relative;
  right: 24px;
  top: 24px;
  transition: all 200ms;
  color: #626da1;
  cursor: pointer;
  align-self: flex-start;
}
@media (max-width: 1200px) {
  .jobs-help-icon {
  align-self: flex-end;}}