.popup-userRfpp {
  margin: auto auto;
  margin-top: 3%;
  background: #fff;
  border-radius: 24px;
  width: 100%;
  min-width: 550px;
  max-width: 700px;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 90%;
  max-height: 600px !important;
  padding-left: 4px;
  padding-right: 2px;
  outline: 0;
  overflow-x: hidden;
}

@media (max-width: 800px) {
  .popup-userRfpp {
    width: 98%;
    min-width: unset;
    height: 97%;
  }
}



.userRfpp-profile-img {
  height: 56px;
  width: 56px;
  margin-left: 15px;
  border-radius: 50px;
}

.userRfpp-scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: scroll;
  scrollbar-color: #c8c5c5 transparent;
  scrollbar-width: thin;
  overflow-x: hidden;
  padding-right: 3px;
  height: 450px;
}


.userRfpp-row-with-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 15px 20px;
}

.userRfpp-row-with-line-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 15px 50px;
}


.userRfpp-column-with-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 100%;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 15px 50px;
}


.chevron-color {
  color: #626DA1;
}

.userRfpp-names {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 20px;
}

.pub-prof-top-fix{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 3px 10px 0px;
  margin: auto auto;
  margin-top: 3%;
  background: #fff;
  border-radius: 24px;
  overflow-y: hidden;
  overflow-x: hidden;
  width:100%;
  max-width: 700px;
  min-width: 550px;
  height:400px;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
@media (max-width: 800px) {
  .pub-prof-top-fix{
    width: 98%;
    min-width: unset;
  }}
  @media (max-width: 400px) {
    .pub-prof-top-fix{
      height: max-content;
    }}
