.card-request {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-width: 400px;
  margin-bottom: 40px;
  /* background-color: #fff; */
  /* border-bottom: 1px solid #bdbdbd; */
  /* border-radius: 24px 24px 0px 0px; */
  margin-top: 20px;
  margin-right:2%;
}
@media(max-width:1200px){
  .card-request {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-width: 100px;
    margin-bottom: 40px;
    margin-right: 0%;
    margin-top: 20px;

  }}

.requests-background-grey1 {
  display: flex;
  flex-direction: row;
justify-content: space-between;
align-items: center;
  width: 100%;
  padding: 20px 20px 10px 25px;
  background-color: #e6e6e6;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
}
@media(max-width:1200px){
.requests-background-grey1 {
padding: 15px 15px 10px 20px;}}

.requests-background-grey2 {
  width: 100%;
  background-color: #e6e6e6;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}


.req-card-headerIcons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
}
