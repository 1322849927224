.popup-job {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  width: 45%;
  max-height: 90%;
  /* position: relative; */
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 850px;
}

.add-job-close {
cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.add-job-3-box-width{
  width:30%;
}

.add-job-2-box-width{
  width:30%;
  margin-right:5%;
}

.add-job-1-box-width{
  width:100%;
}
