.card-lead {
  /* background: #ebebeb; */
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border: 1px solid #dde0f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 48%;
  min-width: 400px;
  margin-bottom: 40px;
  background-color: #fff;
  margin-right:2%;
  transition: all 5s ease-in-out;
}

.lead-card-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 24px;
  padding: 15px 20px 15px 25px;
  height: 100%;
  min-height: 270px;
}
@media (max-width: 800px) {
  .lead-card-box {height: 100%;
    padding: 15px 20px 15px 25px;
    width: 100%;
  }}
  @media (max-width: 500px) {
    .lead-card-box {height: 100%;
      padding: 15x;
      width: 100%;
    }}

.header {
  box-shadow: 5px 12px 26px rgba(0, 35, 75, 0.03);
  border-top: 1px solid #dde0f4;
  display: flex;
  padding: 10px 20px 0px 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lead-box-icon-end{
  display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
min-width: max-content;
}

.title {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}
.bold {
  font-weight: 500;
}
.font {
  font-family: "Lato", sans-serif;
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.skill {
  margin-right: 10px;
}
.headerIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.secondary {
  border: 1px solid #282c34;
  background-color: transparent;
  border-radius: 8px;
  margin-right: 10px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 13px;
}
.primary {
  border: 0px;
  background-color: #282c34;
  border-radius: 8px;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
}

.footer {
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.greyText {
  margin-top: 10px;
  color: grey;
}
.greyFont {
  color: grey;
}

.greenFont {
  color: green;
}

.lead-card-row-start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width:100%;
}

.lead-card-info{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}
@media (max-width: 1350px) {
  .lead-card-info{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }}

.card-information{
  font-family: "Lora";
  font-size: 20px;
  font-weight: 700;
  color: #d9d9d9;
}

.lead-card-skills{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}

.lead-card-skills-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.lead-card-skills-buttons{
  background-color: #eeeeee;
border-radius: 12px;
padding: 5px 15px;
margin-right: 20px;
margin-bottom: 5px;
}

.lead-card-last-row{
  display: flex;
flex-direction: row;
justify-content: flex-start;
}
@media (max-width: 800px) {
  .lead-card-last-row{
    display: flex;
  flex-direction: column;
  justify-content: flex-start;
  }}

  .tryy{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
  }

  .lead-card-opp-box-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  /* @media (max-width: 1350px) {
    .lead-card-opp-box-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    align-items: flex-start;
    }} */
  @media (max-width: 1000px) {
  .lead-card-opp-box-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  width: 100%;
  align-items: flex-start; 
  }}

  .lead-card-skills-4mob{
    display: flex;
flex-direction: row;
justify-content: flex-start;
width: 100%;
  }
  @media (max-width: 500px) {
    .lead-card-skills-4mob{
      display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
    }}