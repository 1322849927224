.row-lightblue-leads {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #f6f9ff;
    border-bottom: 1px solid #bdbdbd;
    height: 100px;
    padding-left: 2%;
    border-top: 1px solid #bdbdbd;

  }
  @media(max-width:1200px){
    .row-lightblue-leads {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: 150px;
      padding: 10px;
    }}
  

    .leads-invoices-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
     
        border-radius: 24px;
        padding: 20px;
        margin-bottom: 50px;
        margin-top: 20px;
      }
      @media (max-width: 1200px) {
        .leads-invoices-card {
          padding: 15px;}}
      

          .white-hack-leadInvoices{
      
              background-color: #fff;
              border-radius: 24px;
            
          }
          @media (max-width: 1200px) {
            .white-hack-leadInvoices{
      
              background-color: unset;
              border-radius: unset;
            
          }}