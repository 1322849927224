.App {
  text-align: center;
}

.heading{
  font-family: "Lato";
  font-size: 32px;
  color: #000;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
flex-direction: column;
justify-content: space-between;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  /* font-family: Proxima Nova !important; */
}
.MuiOutlinedInput-input {
  padding: 10px !important;
}
.MuiFormLabel-root {
  top: -5px !important;
  font-family: Proxima Nova !important;
}
.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Proxima Nova !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Proxima Nova;
  color: #656565;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: Proxima Nova !important;
}
.css-10hburv-MuiTypography-root {
  font-family: Proxima Nova !important;
}

.html {
  height: 100%;
}
.display-linebreak {
  white-space: pre-line;
}
.orange-text {
  color: #ff6210;
}
.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor:default;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.row-space-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.text-Proxima Nova17 {
  font-family: "Proxima Nova";
  font-size: 17px;
  font-weight: 600;
}


.unset-height{
  height:unset !important;
}
.auto-height{
  height:auto;
}
.align-self-stretch{
  align-self: stretch;
}

.row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.row-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.row-flex-end-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: end;
  align-items: center;
  width: 100%;
}

.column-flex-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* 
  height:100%; */
}

.align-self-center{
  align-self: center;
}


.align-self-end{
  align-self: flex-end;
}


.align-self-start{
  align-self: flex-start;
}

.row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.row-start-no-width {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.wrap {
  flex-wrap: wrap;
}
@media (max-width: 1000px) {
.wrap-on-mobile {
  flex-wrap: wrap;
}
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.column-center-no-width {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.column-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.column-center-start-no-width{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

}
.body {
  background-color: #f7f9fa;
}
.column-flex-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.column-start-start-no-width {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  width: 100%;
}


.nowrap{
  white-space: nowrap;
}

.half-page {
  width: 50vw;
}
.max-content-width {
  width: max-content !important;
}

.full-percent-width {
  width: 100%;
}

.half-percent-width {
  width: calc(50% - 10px);
}
.one-fourth-width {
  width: 25%;
}
.three-fourths-width {
  width: 75%;
}
.full-percent-height {
  height: 100%;
}
.padding-normal {
  padding: 10px;
}
.padding-20-30{
padding: 20px 30px;
}

.padding-10-20{
  padding: 10px 20px;
  }

  .padding-t5{
    padding-top: 5px;
  }

.padding-t1p{
  padding-top:1%;
}

.padding-t20{
  padding-top:20px;
}

.padding-r1p5p{
  padding-right:1.5%;
}

.padding-up-down {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-t10{
  padding-top: 10px;
}

.padding-up-down20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-up-down4percent {
  padding-top: 4%;
  padding-bottom: 4%;
}
.padding-b5 {
  padding-bottom: 5px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-b20 {
  padding-bottom: 20px;
}

.padding-r10{
  padding-right: 10px;
}

.padding-t7p{
padding-top:7%
}

.margin-l35{
  margin-left: 35px;
}

.padding-around20{
  padding:20px;
}
.padding-tlsmall{
  padding-top:2px;
  padding-left:5px
}

.column-start-no-width {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.column-space-between-no-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

}
.column-start-between {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  width: 100%;
}

.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}
.no-border{
border:none;
}


.highlight-selected{
  border:10px solid #ff641046;
  border-radius: 8px;
}
.container {
  max-width: 2000px;
  padding: 1% 10% 1% 10%;
  height: 100%;
}
@media (max-width: 800px) {
  .container {
    max-width: 2000px;
    height: 100%;
    padding: 1% 5% 1% 5%;
    overflow-x: hidden;
  }
}

.container-no-height {
  max-width: 2000px;
  padding: 1% 10% 1% 10%;

}
@media (max-width: 800px) {
  .container-no-height {
    max-width: 2000px;

    padding: 1% 5% 1% 5%;
    overflow-x: hidden;
  }
}

.small-Proxima Nova-grey{
  font-family: "Proxima Nova";
  font-size: 14px;
  color:  #afafaf;
}




.card {
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: rgb(179, 177, 177) -1px 1px 5px 1px;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

/* .my-node-enter {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 11;
  background: white;
  position: absolute;
  top: 80px;
  right: -400px;
} */

.my-node {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 11;
  background: white;
  position: absolute;
  top: 80px;

  right: 0px;
}
.my-node-enter-active {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 11;
  background: white;
  position: absolute;
  top: 80px;

  right: 0px;
  transition: right 200ms;
}
/* .my-node-exit {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 11;
  background: white;
  position: absolute;
  top: 80px;
  right: -400px;
} */
/* .my-node-exit-active {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 11;
  background: white;
  position: absolute;
  top: 80px;
  right: -400px;
  transition: right 200ms;
} */

.font-Proxima Nova {
  font-family: "Proxima Nova";
}
.margin-right-small {
  margin-right: 10px;
}
.margin-left {
  margin-left: 30px;
}
.margin-right {
  margin-right: 30px;
}
@media (max-width: 1200px) {
  .margin-right {
    margin-right: 0px;
  }
}
.margin-r132{
  margin-right:132px;
}
.margin-r20 {
  margin-right: 20px;
}

.margin-r3{
  margin-right: 3px;
}


.padding-r5 {
  padding-right: 5px;
}
@media (max-width: 800px) {
  .padding-r5 {
    padding-right: 0px;
  }}

.padding-r20{
  padding-right: 20px;
}

.margin-t5{
  margin-top: 5px;
}
.margin-t10{
  margin-top: 10px;
}

.margin-up-down {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-b150{
  margin-bottom:150px;
}

.margin-b50{
  margin-bottom:50px;
}

.margin-b5{
  margin-bottom:5px;
}


.grey-text {
  color: #656565;
}
.grey-text-margin {
  color: #656565;
  margin-left: 5px;
}
.padding-error{
  padding-top:15px;
}



.margin-t20{
  margin-top: 20px;
}
.margin-t30{
  margin-top: 30px;
}

.margin-t40{
  margin-top: 40px;
}
.margin-t17p{
  margin-top: 17%;
}
.max-width200{
  max-width: 200px;
}
.margin-b20{
  margin-bottom: 20px;
}
.margin-b30{
  margin-bottom: 30px;
}
.margin-b40{
  margin-bottom: 40px;
}
.margin-b10{
  margin-bottom: 10px;
}
.margin-b10n{
  margin-bottom: 10px;
}

.margin-l10{
  margin-left:10px;
}

.margin-l20{
  margin-left:20px;
}

.padding-r5{
  padding-right: 5px;
}

.blue {
  color: #0645ad !important;
}


.grey-blue {
  color: #626da1;
}
.white {
  color: #fff;
}

.orange{
  color: #ff6210;
}

.Proxima Nova15{
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 15px;
}

/* .css-i4bv87-MuiSvgIcon-root {

} */

.separator{
  background: #626da1;
  height: 0.5px;
  margin-top: 20px;
  opacity: 0.2;

}


/* .configurator-out .css-i4bv87-MuiSvgIcon-root {
  margin-top: -7px;
  margin-right: 0px;
  color: #fff;
} */

/* .px-16 {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */


@media (max-width: 800px) {
.css-y0lt6o {
  min-width: 300px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
}

.skills-select-menu{

    max-height:200px;
    overflow-y: auto;
    overflow-x: hidden;
}
